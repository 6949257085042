<template>
  <div class="website-page">
    <!-- 登陆 -->
    <header class="website-header-wrap">
      <div><a href="#" class="website-logo">iooka</a></div>
      <div class="website-button-group">
        <span @click="goRegister">成为代理商</span>
        <span @click="goLogin">登录</span>
        <el-button type="primary" round @click="goRegister">注册</el-button>
      </div>
    </header>

    <!-- banner -->
    <div class="bannerLogo" />

    <!-- 模块1 -->
    <div class="book-wrap">
      <div class="book-wrap-inner">
        <div class="book-content">
          <div class="book-content-inner">
            <div class="book-text1">呦呦 Pad</div>
            <div class="book-text2">
              定制化平板电脑，免费试用，<br />预约送8.5折剧本优惠券
            </div>
            <el-button
              type="primary"
              size="middle"
              class="button-btn"
              round
              @click="goRegister"
              >立即预约</el-button
            >
          </div>
        </div>
        <div class="book-img-wrap">
          <div class="book-img-inner">
            <img
              src="@/assets/website/pad_sub1.png"
              alt=""
              class="img-pad-sub1"
            />
            <img src="@/assets/website/pad.png" alt="" class="img-pad" />

            <span class="img-pad-sub2" />
          </div>
        </div>
      </div>
    </div>
    <!-- 模块2 -->
    <div class="advantage-wrap">
      <div class="advantage-inner">
        <div class="advantage-tips">
          <div class="book-text1">Why US？<span /></div>
          <div class="book-text2">把展会搬到我家客厅</div>
        </div>
        <div class="advantage-img-wrap">
          <div
            class="advantage-img-itam1"
            @mouseenter="picFlag = 2"
            @mouseleave="picFlag = 1"
          >
            <img v-show="picFlag == 1" src="@/assets/website/ad1.png" alt="" />
            <img v-show="picFlag == 2" src="@/assets/website/a12.jpg" alt="" />
          </div>
          <div
            class="advantage-img-itam2"
            @mouseenter="rightFlag = 2"
            @mouseleave="rightFlag = 1"
          >
            <img
              v-show="rightFlag == 1"
              src="@/assets/website/DM2.png"
              alt=""
            />
            <img
              v-show="rightFlag == 2"
              src="@/assets/website/ad22.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 模块3 -->
    <div class="activity-wrap">
      <div class="activity-tips">
        <div class="book-text2" style="margin-top: 86px">
          活动上新，新手老板快来参加
        </div>
      </div>
      <div class="activity-inner">
        <div
          class="activity-item-wrap"
          @click="dialogVisibleNew = !dialogVisibleNew"
        >
          <div class="activity-img imageBk1" />
          <div>
            <div class="activity-name">新店扶持计划</div>
            <div class="activity-des">购机送送送</div>
            <div class="activity-button">
              <img src="@/assets/website/button.jpg" alt="" />
            </div>
          </div>
          <img
            src="@/assets/website/activity_icon1.png"
            alt=""
            srcset=""
            class="activity-icon activity-icon1"
          />
        </div>
        <div class="activity-item-wrap" @click="dialogVisible = !dialogVisible">
          <div class="activity-img imageBk2" />
          <div>
            <div class="activity-name">发行早鸟计划</div>
            <div class="activity-des">传本送送送</div>
            <div class="activity-button">
              <img src="@/assets/website/button.jpg" alt="" />
            </div>
          </div>
          <img
            src="@/assets/website/activity_icon2.png"
            alt=""
            srcset=""
            class="activity-icon activity-icon2"
          />
        </div>
        <div
          class="activity-item-wrap"
          @click="dialogVisibleForeshow = !dialogVisibleForeshow"
        >
          <div class="activity-img imageBk3" />
          <div>
            <div class="activity-name">新本预告</div>
            <div class="activity-des">更多活动，敬请关注官方公众号</div>
            <div class="activity-button">
              <img src="@/assets/website/button.jpg" alt="" />
            </div>
          </div>
          <img
            src="@/assets/website/activity_icon3.png"
            alt=""
            srcset=""
            class="activity-icon activity-icon3"
          />
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer>
      <div class="foot-first">
        <div class="aboutUs">
          <p class="title">关于我们</p>
          <p>
            爱偶咖是一个以平板电脑为载体，连接线下剧本杀发行、店家与玩家的全产业链开放平台。
          </p>
          <p>
            爱偶咖团队由来自游戏、内容、电商平台以及知产律师等多个跨行业背景经验的专业人士组成。
          </p>
        </div>
        <div class="contactUs">
          <p class="title">联系我们</p>
          <div class="contactUs_content">
            <div class="leftContent">
              <p class="content_title">发行合作、剧本定制</p>
              <p>微信：E960WSKheart</p>
              <p>邮箱：bd@iooka.cn</p>
            </div>
            <div class="rightContent">
              <div class="rightFirst">
                <p class="content_title">客户服务</p>
                <p>service@iooka.cn</p>
              </div>
              <div class="rightSecond">
                <p class="content_title">其他合作</p>
                <p>cooperation@iooka.cn</p>
              </div>
            </div>
          </div>
        </div>
        <div class="privacyPolicy">
          <p class="title">隐私政策</p>
          <img src="@/assets/website/code.jpg" />
          <p>微信公众号</p>
        </div>
      </div>
      <div class="foot-bottom">
        <p>
          Copyright © 2023 中文在线集团股份有限公司泰州分公司 版权所有
        </p>

        <a href="https://beian.miit.gov.cn/" target="_blank"
          >苏ICP备2022016253号</a
        >
      </div>
    </footer>
    <!-- 弹框 -->
    <el-dialog
      class="dialog-box"
      :visible.sync="dialogVisible"
      title="早鸟计划"
    >
      <div class="msg-cont-box" style="padding: 0 20px">
        <p>
          ①分成优惠：平台正常抽成30%；凡试运营期间（约3个月）完成注册、认证、传本、通过审核的版权方，以上线时间为起始时间，半年内，前20名免收渠道费；21-40名收取10%；41-100收取20%。
        </p>
        <p>
          ②参与在线展会，免费送平板：首次发起测本时缴纳设备押金，平台发放平板，完成后押金全额返还，实际未参与测本则扣除押金。
        </p>
      </div>
    </el-dialog>
    <el-dialog
      class="dialog-box"
      :visible.sync="dialogVisibleNew"
      title="新店扶持计划"
    >
      <div class="msg-cont-box" style="padding: 0 20px">
        <p>
          <span style="font-weight: bold">购买平板1套（含免费试用期间），</span
          >送6本剧本（指定类目下自选）；
        </p>
        <p>
          <span style="font-weight: bold">购买平板2套，</span
          >加送限量剧本1份或普通本2份；
        </p>
        <p>
          <span style="font-weight: bold">购买平板3套，</span
          >加送一年免费扩容期，价值600元；
        </p>
      </div>
    </el-dialog>
    <el-dialog
      class="dialog-box"
      :visible.sync="dialogVisibleForeshow"
      title="新本速递，敬请关注官方公众号"
    >
      <img src="@/assets/website/code.jpg" />
    </el-dialog>
  </div>
</template>

<script>
import "./index.scss";
export default {
  name: "Website",
  data() {
    return {
      dialogVisibleNew: false,
      dialogVisible: false,
      dialogVisibleForeshow: false,
      picFlag: 1,
      rightFlag: 1,
    };
  },
  methods: {
    goLogin() {
      window.location.href = "http://workshop.iooka.cn/#/login?type=0";
    },
    goRegister() {
      window.location.href = "http://workshop.iooka.cn/#/login?type=1";
    },
  },
};
</script>
