/*
 * @Author: zhangqian zhangqian@shangzhao360.com
 * @Date: 2022-12-28 17:16:50
 * @LastEditors: zhangqian zhangqian@shangzhao360.com
 * @LastEditTime: 2022-12-28 17:21:03
 * @FilePath: /home/my-project/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'; //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'; //element-ui的css


Vue.use(ElementUI); //使用elementUI
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
