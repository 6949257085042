<template>
  <div id="app">
    <home />
  </div>
</template>

<script>
import home from "./components/home.vue";
export default {
  name: "App",
  components: {
    home,
  },
};
</script>
<style>
body {
  margin: 0 !important;
}
</style>
